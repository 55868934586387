// Copyright © 2024 The Things Industries B.V.

import React, { useCallback, useMemo, useState } from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import { IconChartBar } from '@ttn-lw/components/icon'
import Panel, { PanelError } from '@ttn-lw/components/panel'
import Toggle from '@ttn-lw/components/panel/toggle'

import RequireRequest from '@ttn-lw/lib/components/require-request'
import Message from '@ttn-lw/lib/components/message'
import DateTime from '@ttn-lw/lib/components/date-time'

import HeatmapChart from '@console/components/heatmap-chart'
import BlurryNetworkActivityPanel from '@console/components/blurry-network-activity-panel'

import PropTypes from '@ttn-lw/lib/prop-types'
import {
  selectNocEnabled,
  selectNocUrl,
  selectPluginTTSSandboxEnabled,
} from '@ttn-lw/lib/selectors/env'
import sharedMessages from '@ttn-lw/lib/shared-messages'
import { generateDatesInInterval, subtractDays } from '@ttn-lw/lib/date-utils'

import {
  getApplicationPacketsPerChannel,
  getApplicationPacketsPerDataRate,
  getEndDevicePacketsPerChannel,
  getEndDevicePacketsPerDataRate,
  getGatewayPacketsPerChannel,
  getGatewayPacketsPerDataRate,
} from '@console/store/actions/network-operations-center.tti'

import {
  selectApplicationPacketsPerChannel,
  selectApplicationPacketsPerDataRate,
  selectEndDevicePacketsPerChannel,
  selectEndDevicePacketsPerDataRate,
  selectGatewayPacketsPerChannel,
  selectGatewayPacketsPerDataRate,
  selectNocExtendedAccess,
} from '@console/store/selectors/network-operations-center.tti'
import { selectUserIsAdmin } from '@console/store/selectors/user'

import style from './network-activity-panel.tti.styl'

const m = defineMessages({
  networkActivityUnavailable: 'The network activity is currently unavailable',
  notEnoughData: 'Not enough data available to display this chart',
  onlyAdminMessage: 'Network activity statistics are currently only visible to admins',
})

const toggleOptions = [
  { label: sharedMessages.packetsPerDataRate, value: 0 },
  { label: sharedMessages.packetsPerChannel, value: 1 },
]

const NetworkActivityPanel = ({ applicationId, deviceId, gatewayId, showRandomValues }) => {
  const nocEnabled = selectNocEnabled()
  const sandboxEnabled = useSelector(selectPluginTTSSandboxEnabled)
  const nocExtendedAccess = useSelector(selectNocExtendedAccess)
  const nocUrl = selectNocUrl({
    appId: applicationId,
    devId: deviceId,
    gtwId: gatewayId,
    extended: nocExtendedAccess,
  })
  const gatewayPacketsPerDataRate = useSelector(selectGatewayPacketsPerDataRate)
  const gatewayPacketsPerChannel = useSelector(selectGatewayPacketsPerChannel)
  const applicationPacketsPerDataRate = useSelector(selectApplicationPacketsPerDataRate)
  const applicationPacketsPerChannel = useSelector(selectApplicationPacketsPerChannel)
  const endDevicePacketsPerDataRate = useSelector(selectEndDevicePacketsPerDataRate)
  const endDevicePacketsPerChannel = useSelector(selectEndDevicePacketsPerChannel)
  const isAdmin = useSelector(selectUserIsAdmin)
  const [activeToggle, setActiveToggle] = useState(0)

  const from = subtractDays(new Date(), 1)
  const to = new Date()
  const bucketInterval = '1000s'

  const isGateway = useMemo(() => Boolean(gatewayId), [gatewayId])
  const isApplication = useMemo(
    () => Boolean(applicationId) && !Boolean(deviceId),
    [applicationId, deviceId],
  )
  const isEndDevice = useMemo(
    () => Boolean(applicationId) && Boolean(deviceId),
    [applicationId, deviceId],
  )

  const packetsPerDataRate = useMemo(() => {
    if (isGateway) return gatewayPacketsPerDataRate
    if (isApplication) return applicationPacketsPerDataRate
    if (isEndDevice) return endDevicePacketsPerDataRate
    return {}
  }, [
    applicationPacketsPerDataRate,
    endDevicePacketsPerDataRate,
    gatewayPacketsPerDataRate,
    isApplication,
    isEndDevice,
    isGateway,
  ])

  const packetsPerChannel = useMemo(() => {
    if (isGateway) return gatewayPacketsPerChannel
    if (isApplication) return applicationPacketsPerChannel
    if (isEndDevice) return endDevicePacketsPerChannel
    return {}
  }, [
    applicationPacketsPerChannel,
    endDevicePacketsPerChannel,
    gatewayPacketsPerChannel,
    isApplication,
    isEndDevice,
    isGateway,
  ])

  const handleToggleChange = useCallback((_, value) => {
    setActiveToggle(value)
  }, [])

  const mappedData = useMemo(() => {
    const packetsObj = activeToggle === 0 ? packetsPerDataRate : packetsPerChannel

    return Object.keys(packetsObj).map(key => ({
      name: key,
      data: packetsObj[key].map(p => ({ x: new Date(p.time).getTime(), y: Number(p.count) })),
    }))
  }, [activeToggle, packetsPerChannel, packetsPerDataRate])

  const dates = generateDatesInInterval(
    mappedData.length ? new Date(mappedData[0].data[0].x) : from,
    5,
  )

  const renderChart = useMemo(() => {
    if (!Boolean(mappedData.length) && !showRandomValues) {
      return (
        <PanelError>
          <Message
            component="p"
            content={m.notEnoughData}
            className="c-text-neutral-light text-center"
          />
        </PanelError>
      )
    }

    return (
      <>
        <HeatmapChart showRandomValues={showRandomValues} data={mappedData} />
        <div className="d-flex al-center j-between">
          {dates.map((d, idx) => (
            <DateTime
              key={`chart-date-${idx}`}
              value={d}
              timeFormatOptions={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              dateFormatOptions={{
                month: '2-digit',
                day: '2-digit',
              }}
              className={style.chartDate}
            />
          ))}
        </div>
      </>
    )
  }, [dates, mappedData, showRandomValues])

  let requestActions = []

  if (!showRandomValues && nocEnabled) {
    requestActions = [
      ...(isGateway
        ? [
            getGatewayPacketsPerDataRate(
              gatewayId,
              from.toISOString(),
              to.toISOString(),
              bucketInterval,
            ),
            getGatewayPacketsPerChannel(
              gatewayId,
              from.toISOString(),
              to.toISOString(),
              bucketInterval,
            ),
          ]
        : isApplication
          ? [
              getApplicationPacketsPerDataRate(
                applicationId,
                from.toISOString(),
                to.toISOString(),
                bucketInterval,
              ),
              getApplicationPacketsPerChannel(
                applicationId,
                from.toISOString(),
                to.toISOString(),
                bucketInterval,
              ),
            ]
          : isEndDevice
            ? [
                getEndDevicePacketsPerDataRate(
                  applicationId,
                  deviceId,
                  from.toISOString(),
                  to.toISOString(),
                  bucketInterval,
                ),
                getEndDevicePacketsPerChannel(
                  applicationId,
                  deviceId,
                  from.toISOString(),
                  to.toISOString(),
                  bucketInterval,
                ),
              ]
            : []),
    ]
  }

  // The rendering of the panels is based on the user's permissions and the availability of the NOC.
  // Note the different setups:
  // 1. isAdmin
  //   a. nocEnabled - show panel on gateway overview
  //   b. nocEnabled && nocExtended - show panel for apps and devices
  //   c. !nocEnabled - blurry panel
  // 2. !isAdmin && sandbox - blurry panel
  // 3. all other cases - nothing

  const needsNocExtendedAccess = (isEndDevice || isApplication) && !nocExtendedAccess
  const isNocEnabled = nocEnabled && Boolean(nocUrl)

  if (!isAdmin && sandboxEnabled) {
    return <BlurryNetworkActivityPanel />
  }

  if (isAdmin) {
    if (!isNocEnabled || needsNocExtendedAccess) {
      return <BlurryNetworkActivityPanel />
    }
    return (
      <Panel
        title={sharedMessages.networkActivity}
        icon={IconChartBar}
        shortCutLinkTitle={sharedMessages.nocShortened}
        shortCutLinkPath={nocUrl}
        shortCutLinkTarget="_blank"
        shortCutLinkDisabled={!nocEnabled}
        className={style.panel}
      >
        {!isAdmin && !sandboxEnabled ? (
          <PanelError>
            <Message
              component="p"
              content={m.onlyAdminMessage}
              className="c-text-neutral-light text-center"
            />
          </PanelError>
        ) : (
          <RequireRequest
            spinnerProps={{ inline: true, center: true }}
            errorRenderFunction={() => (
              <PanelError>
                <Message
                  component="p"
                  content={m.networkActivityUnavailable}
                  className="c-text-neutral-light text-center"
                />
              </PanelError>
            )}
            requestAction={requestActions}
          >
            <Toggle
              options={toggleOptions}
              active={activeToggle}
              onToggleChange={handleToggleChange}
              fullWidth
            />
            <div className={style.content}>{renderChart}</div>
          </RequireRequest>
        )}
      </Panel>
    )
  }

  return null
}

NetworkActivityPanel.propTypes = {
  applicationId: PropTypes.string,
  deviceId: PropTypes.string,
  gatewayId: PropTypes.string,
  showRandomValues: PropTypes.bool,
}

NetworkActivityPanel.defaultProps = {
  deviceId: undefined,
  applicationId: undefined,
  gatewayId: undefined,
  showRandomValues: false,
}

export default NetworkActivityPanel
