// Copyright © 2024 The Things Industries B.V.

import React, { useMemo, useState } from 'react'
import { defineMessages, FormattedNumber } from 'react-intl'
import ReactApexChart from 'react-apexcharts'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { APPLICATION, GATEWAY } from '@console/constants/entities'

import { IconArrowDown, IconArrowUp, IconPlus } from '@ttn-lw/components/icon'
import Panel, { PanelError } from '@ttn-lw/components/panel'
import Badge from '@ttn-lw/components/badge'
import Toggle from '@ttn-lw/components/panel/toggle'
import Button from '@ttn-lw/components/button'

import RequireRequest from '@ttn-lw/lib/components/require-request'
import Message from '@ttn-lw/lib/components/message'

import BlurryNocMetricsPanel from '@console/components/blurry-noc-metrics-panel'

import {
  getRandomSeries,
  getSeries,
  options,
} from '@console/containers/noc-metrics-panel/chart-utils.tti'

import PropTypes from '@ttn-lw/lib/prop-types'
import {
  selectApplicationRootPath,
  selectNocEnabled,
  selectNocUrl,
  selectPluginTTSSandboxEnabled,
} from '@ttn-lw/lib/selectors/env'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import { setSearchOpen, setSearchScope } from '@console/store/actions/search'

import { selectUserIsAdmin } from '@console/store/selectors/user'
import { selectNocExtendedAccess } from '@console/store/selectors/network-operations-center.tti'

import style from './noc-metrics-panel.tti.styl'

const nocEntityPages = {
  [APPLICATION]: '/d/extended-ttsapplication/application-details',
  [GATEWAY]: '/d/extended-ttsgateway/gateway-details',
  DEVICE: '/d/extended-ttsapplication/application-details',
}

const m = defineMessages({
  networkStatusUnavailable: 'The {entity} network status is currently unavailable',
  noEntitiesTitle: 'No {entities}',
  noEntitiesSubtitle: 'Your {entities} will appear here.',
  addEntity: 'Add {entity}',
})

const getBadgeProps = (currentCount, pastCount) => {
  if (currentCount < pastCount) {
    return { status: 'error', endIcon: IconArrowDown }
  } else if (currentCount > pastCount) {
    return { status: 'success', endIcon: IconArrowUp }
  }
  return { status: 'info' }
}

const toggleOptions = [
  { label: '7 days', value: 7 },
  { label: '30 days', value: 30 },
]

const NocMetricsPanel = ({
  title,
  icon,
  fetchData,
  data,
  totalCount,
  showRandomValues,
  entity,
  entityPath,
}) => {
  const nocUrl = selectNocUrl() + nocEntityPages[entity]
  const nocEnabled = selectNocEnabled()
  const sandboxEnabled = useSelector(selectPluginTTSSandboxEnabled)
  const nocExtendedAccess = useSelector(selectNocExtendedAccess)
  const isAdmin = useSelector(selectUserIsAdmin)
  const dispatch = useDispatch()

  const appRoot = selectApplicationRootPath()
  const [activeToggle, setActiveToggle] = useState(7)

  const entityLowercase = entity.toLowerCase()

  const computedData = useMemo(
    () => (showRandomValues ? getRandomSeries(totalCount) : data),
    [data, showRandomValues, totalCount],
  )

  const currentCount = useMemo(
    () => computedData[computedData.length - 1]?.count ?? 0,
    [computedData],
  )
  const pastCount = useMemo(
    () => computedData[computedData.length - activeToggle - 1]?.count ?? 0,
    [computedData, activeToggle],
  )

  const handleToggleChange = React.useCallback((_, value) => {
    setActiveToggle(value)
  }, [])

  const requestActions = []

  if (!showRandomValues) {
    requestActions.push(fetchData())
  }

  const handleRegisterDeviceClick = React.useCallback(() => {
    dispatch(setSearchScope(APPLICATION))
    dispatch(setSearchOpen(true))
  }, [dispatch])

  const addButtonProps = {
    primary: true,
    message: m.addEntity,
    messageValues: {
      entity: sharedMessages[entityLowercase].defaultMessage.toLowerCase(),
    },
    icon: IconPlus,
  }

  const areEntitiesActive = useMemo(
    () => computedData.some(item => item.count && item.count !== 0),
    [computedData],
  )

  // The rendering of the panels is based on the user's permissions and the availability of the NOC.
  // Note the different setups:
  // 1. isAdmin
  //   a. nocEnabled - show panel on for gateways and devices
  //   b. nocEnabled && nocExtended - show panel for apps
  //   c. !nocEnabled - blurry panel
  // 2. !isAdmin && sandbox - blurry panel
  // 3. all other cases - nothing

  const needsNocExtendedAccess = entity !== GATEWAY && !nocExtendedAccess
  const isNocEnabled = nocEnabled && Boolean(nocUrl)

  if (!isAdmin && sandboxEnabled) {
    return (
      <BlurryNocMetricsPanel entity={entity} entityPath={entityPath} icon={icon} title={title} />
    )
  }

  if (isAdmin) {
    if (!isNocEnabled || needsNocExtendedAccess) {
      return (
        <BlurryNocMetricsPanel entity={entity} entityPath={entityPath} icon={icon} title={title} />
      )
    }
    return (
      <Panel
        title={title}
        icon={icon}
        shortCutLinkTitle={
          areEntitiesActive || !entityPath
            ? sharedMessages.nocShortened
            : sharedMessages[`${entityLowercase}s`]
        }
        shortCutLinkPath={areEntitiesActive || !entityPath ? nocUrl : entityPath}
        shortCutLinkTarget={areEntitiesActive || !entityPath ? '_blank' : undefined}
        className={style.panel}
        compact
      >
        <RequireRequest
          spinnerProps={{ inline: true, center: true }}
          errorRenderFunction={() => (
            <PanelError>
              <Message
                className="c-text-neutral-light text-center"
                component="p"
                content={m.networkStatusUnavailable}
                values={{ entity: sharedMessages[entityLowercase].defaultMessage.toLowerCase() }}
              />
            </PanelError>
          )}
          requestAction={requestActions}
        >
          {areEntitiesActive ? (
            <>
              <div
                className={classnames(
                  style.statusContainer,
                  'd-flex',
                  'j-between',
                  'al-center',
                  'mb-cs-m',
                  'flex-wrap',
                )}
              >
                <div className="d-flex al-center gap-cs-xs">
                  <span className="c-text-neutral-heavy fw-bold fs-xl4">
                    <FormattedNumber value={currentCount} />
                  </span>
                  <span className="c-text-neutral-heavy fw-bold fs-xxl">/</span>
                  <span className="c-text-neutral-heavy fw-bold fs-xxl">
                    <FormattedNumber value={totalCount} />
                  </span>
                </div>
                <Badge {...getBadgeProps(currentCount, pastCount)}>
                  <FormattedNumber
                    value={currentCount - pastCount}
                    style="decimal"
                    signDisplay="exceptZero"
                  />
                </Badge>
              </div>
              <ReactApexChart
                options={options}
                series={getSeries(computedData, activeToggle)}
                type="area"
                height={100}
              />
              <Toggle
                options={toggleOptions}
                active={activeToggle}
                onToggleChange={handleToggleChange}
                fullWidth
              />
            </>
          ) : (
            <div className={style.content}>
              <Message
                className="c-text-neutral-heavy fw-bold fs-l text-center"
                content={m.noEntitiesTitle}
                values={{
                  entities: title.defaultMessage.toLowerCase(),
                }}
              />
              <Message
                className="c-text-neutral-light fs-m text-center mb-cs-l"
                content={m.noEntitiesSubtitle}
                values={{
                  entities: title.defaultMessage.toLowerCase(),
                }}
              />
              {Boolean(entityPath) ? (
                <Button.AnchorLink {...addButtonProps} href={`${appRoot}${entityPath}/add`} />
              ) : (
                <Button {...addButtonProps} onClick={handleRegisterDeviceClick} />
              )}
            </div>
          )}
        </RequireRequest>
      </Panel>
    )
  }

  return null
}

NocMetricsPanel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string.isRequired,
      count: PropTypes.number,
    }),
  ).isRequired,
  entity: PropTypes.oneOf([APPLICATION, GATEWAY, 'DEVICE']).isRequired,
  entityPath: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  icon: PropTypes.icon.isRequired,
  showRandomValues: PropTypes.bool.isRequired,
  title: PropTypes.message.isRequired,
  totalCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

NocMetricsPanel.defaultProps = {
  entityPath: undefined,
}

export default NocMetricsPanel
